'use client';

import { lock, unlock, clearBodyLocks } from 'tua-body-scroll-lock';

import { ReactNode, useEffect, useRef, useState } from 'react';

import { cn } from '@/common/utils/cn';

import {
  ArrowUpRightIcon,
  ChevronRight,
  LanguagesIcon,
  MenuIcon,
  PlusIcon,
} from 'lucide-react';

import { Slot } from '@radix-ui/react-slot';
import Link from 'next/link';
import { Accordion } from '../Accordion';
import { Button } from '../Button/Button';
import { LanguageSelector } from '../Language/LanguageSelector';
import { ROUTE_LIST } from './routes';

import { pathWithLocale } from '@/common/utils/pathWithLocale';
import { Language } from '@/i18n';
import { usePathname } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '@landing/providers/MobileProvider';

export function MobileLinks() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const isDropdownOpenRef = useRef(false);
  const { i18n, t } = useTranslation();
  const pathname = usePathname();
  const [, lang] = pathname.trim().split('/');
  const scrollableElementRef = useRef<HTMLDivElement>(null);
  const dropdownElementRef = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  function calculateScrollBarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
  }

  useEffect(() => {
    if (!scrollableElementRef.current || !isMobile) return;
    const header = document.querySelector('header');
    if (isDropdownOpen) {
      if (header)
        header.style.setProperty(
          'padding-right',
          `${calculateScrollBarWidth()}px`,
        );
      lock(scrollableElementRef.current);
    } else {
      if (header) header.style.setProperty('padding-right', '');
      unlock(scrollableElementRef.current);
    }

    return () => {
      clearBodyLocks();
      if (header) header.style.setProperty('padding-right', '');
    };
  }, [isDropdownOpen, isMobile]);

  const toggleDropdown = () => {
    setDropdownOpen((previousOpen) => {
      isDropdownOpenRef.current = !isDropdownOpenRef.current;
      return !previousOpen;
    });
  };

  useEffect(() => {
    function listener(e: MouseEvent) {
      if (!dropdownElementRef.current || isMobile) return;
      if (!dropdownElementRef.current?.contains(e.target as Node)) {
        setDropdownOpen(false);
      }
    }

    window.addEventListener('click', listener, { capture: true });

    return () => window.removeEventListener('click', listener);
  }, [isMobile]);

  return (
    <div className='flex items-center'>
      <button
        onClick={toggleDropdown}
        type='button'
        className='relative h-full p-4 -mr-4 z-40'
      >
        <>
          {isDropdownOpen ? (
            <PlusIcon className={cn('size-7 rotate-45')} />
          ) : (
            <MenuIcon className='size-7' />
          )}
        </>
      </button>

      <div
        ref={dropdownElementRef}
        className={`z-30 fixed sm:grid right-0 top-[59px] md:top-[69px] max-sm:max-h-[calc(100dvh_-_59px)] overflow-y-auto w-full rounded-b-xl bg-white text-gray-900 shadow-lg transition-[height,_transform,grid-template-rows] scrollbar-hide duration-300 ease-in-out md:w-[23.4375rem] md:mr-4 ${
          isDropdownOpen
            ? // ? 'max-sm:h-screen sm:h-auto sm:grid-rows-[1fr]'
              `max-sm:max-h-[calc(100dvh_-_59px)] h-dvh sm:h-auto sm:grid-rows-[1fr]`
            : `max-sm:h-0 sm:grid-rows-[0fr]`
        }`}
      >
        <div
          ref={scrollableElementRef}
          className={cn(
            'overflow-hidden flex w-full h-full flex-row border-[#E4EBFF] border sm:border-t-0',
            isDropdownOpen
              ? 'border-b overflow-y-auto hide-scroll-on-animation [--hide-scroll-on-animation-duration:0.3s]'
              : 'border-b-0',
          )}
        >
          <div className='flex w-full flex-col h-full p-4'>
            {ROUTE_LIST.map((route) => (
              <MobileNavbarItem
                key={route.href}
                asChild
                onClick={toggleDropdown}
              >
                <Link
                  href={
                    route.href.match(/^https?:\/\//) !== null
                      ? route.href
                      : pathWithLocale(route.href, lang)
                  }
                  target={route.target}
                >
                  <MobileNavbarIcon>{route.icon}</MobileNavbarIcon>
                  {t(route.i18nKey)}
                </Link>
              </MobileNavbarItem>
            ))}

            <MobileNavbarItem className='w-full'>
              <Accordion type='single' collapsible className='w-full py-0'>
                <Accordion.Item value={i18n.language}>
                  <Accordion.Trigger className='text-left gap-x-3 justify-start py-0'>
                    <MobileNavbarIcon>
                      <LanguagesIcon className='size-5' />
                    </MobileNavbarIcon>
                    {t('navbar.language')}
                    <ChevronRight className='size-4 text-subtle transition group-data-[state=open]/accordion-trigger:rotate-90 ml-auto' />
                  </Accordion.Trigger>
                  <Accordion.Content className='border p-2.5 py-4 rounded-lg mt-4'>
                    <LanguageSelector
                      lang={i18n.language as Language}
                      className='px-1'
                    />
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion>
            </MobileNavbarItem>
            <div
              className={cn(
                'w-full flex flex-col items-center gap-3 mt-auto pt-4 pb-8 md:pb-0 md:px-0',
                '*:max-w-lg *:w-full md:*:max-w-none',
              )}
            >
              <Button
                asChild
                variant='default'
                className='flex gap-x-0.5 items-center h-[3.25rem] md:h-9'
              >
                <Link href='/ramp'>
                  Sphere ramp
                  <ArrowUpRightIcon className='w-5 h-auto -mr-1' />
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface SidebarItemProps {
  isActive?: boolean;
  className?: string;
  asChild?: boolean;
  onClick?: () => void;
}
function MobileNavbarItem<T extends React.ElementType>({
  className,
  asChild,
  ...props
}: SidebarItemProps &
  Omit<React.ComponentPropsWithoutRef<T>, keyof SidebarItemProps>) {
  const Component = asChild ? Slot : 'div';

  return (
    <Component
      className={cn(
        'flex items-center gap-x-3 w-full p-2 rounded-md text-sm font-medium text-variant cursor-pointer transition-colors hover:bg-background-dim/50',
        className,
      )}
      {...props}
    />
  );
}

function MobileNavbarIcon({ children }: { children: ReactNode }) {
  return (
    <span className='flex shrink-0 items-center justify-center size-10 text-brand bg-brand/5 border border-brand/10 rounded-md *:shrink-0'>
      {children}
    </span>
  );
}
